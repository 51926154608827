import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFound = ({ location }) => {
  const pageLang = 'en-us';
  const altLangs = [{ lang: 'de-de', uid: '404' }];

  return (
    <Layout pageLang={pageLang} altLangs={altLangs} location={location}>
      <StyledContainer>
        <h1>404</h1>
        <h2>Document not found</h2>
        <p>
          <a href="/">Return to homepage</a>
        </p>
      </StyledContainer>
    </Layout>
  );
};

export default NotFound;
